const SERVER_SIDE = typeof window === 'undefined';

export const useFirebase = () => {
    if (!SERVER_SIDE) {
        const firebase = window.firebase;
        const location = window.location;
        if (location.hostname === 'localhost' && firebase) {
            firebase.functions().useFunctionsEmulator('http://localhost:5001');
        }
        return firebase;
    }
};

export const analytics = !SERVER_SIDE && useFirebase() && useFirebase().analytics();

export const addContact = !SERVER_SIDE && useFirebase() && useFirebase().functions().httpsCallable('addContact');