/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ meta, lang, slug, title, description, image }) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            description
            siteUrl
            social {
              twitter
            }
          }
        }
      }
    `
  );

  const metaTitle = title || siteMetadata.title;
  const metaDescription = description || siteMetadata.description;
  const metaImage = image ? `${siteMetadata.siteUrl}/${image}` : null;
  const ogUrl = `${siteMetadata.siteUrl}${slug}`;
  const twitterUrl = siteMetadata.social.twitter;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={metaTitle}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: ogUrl,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: twitterUrl,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ]
        .concat(
          metaImage
            ? [
              {
                property: 'og:image',
                content: metaImage,
              },
              {
                property: 'twitter:image',
                content: metaImage,
              },
            ]
            : []
        )
        .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  meta: [],
  slug: '',
  title: '',
  description: ``,
};

SEO.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.object),
  lang: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
};

export default SEO;
