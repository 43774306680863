const SERVER_SIDE = typeof window === 'undefined';

let sentry;

export const useSentry = () => {
    if (!SERVER_SIDE && !sentry) {
        sentry = window.Sentry;
        sentry.init({
            dsn: 'https://6b47a7b9b25043c7bf8995ada52dd72e@sentry.io/1860286'
        });
    };
    return sentry;
}