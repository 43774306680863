import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { getTheme } from 'lib/theme';
import { analytics } from 'lib/firebase';
import { useSentry } from 'lib/sentry';
import Header from 'components/Header';
import SEO from 'components/SEO';

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  // remove server-side generated css to avoid race conditions during hydration
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);
  // initalize Sentry
  useSentry();

  const theme = createMuiTheme(getTheme(true));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SEO title='Edmund Korley' />
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Box mt={6}>
          <Container maxWidth='md'>{children}</Container>
        </Box>
      </>
    </ThemeProvider>
  );
}
