import React from 'react';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';

export const wrapRootElement = ({ element }) => {
    const generateClassName = createGenerateClassName({
        disableGlobal: true,
        productionPrefix: 'emk'
    });
    return (
        <StylesProvider generateClassName={generateClassName}>
            {element}
        </StylesProvider>
    );
}