import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

import Link from 'components/Link';

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
};

const Header = withStyles(theme => ({
  headerTitle: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white
  },
}))(function Header({ classes, siteTitle }) {
  return (
    <HideOnScroll>
      <AppBar color='secondary' position='sticky' elevation={0}>
        <Toolbar variant='dense' disableGutters>
          <Container maxWidth='md'>
            <Link
              className={classes.headerTitle}
              variant='h5'
              underline='none'
              to='/'>
              {siteTitle}
            </Link>
          </Container>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
});

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
};

export default Header;
