module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Edmund Korley","short_name":"Korley","description":"Ideas from Edmund Korley","lang":"en","start_url":"/words/","background_color":"#ff6d00","theme_color":"#ff6d00","display":"minimal-ui","icon":"src/lib/images/e.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/edmund/dev/edmundkorley-net/web/src/components/Layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
