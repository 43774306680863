const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 795,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      light: '#ffffff',
      main: '#fafafa',
      dark: '#c7c7c7',
      contrastText: '#000000',
    },
    secondary: {
      light: '#ff9e40',
      main: '#ff6d00',
      dark: '#c43c00',
      contrastText: '#000000',
    },
  },
  typography: {
    fontFamily: '"Spectral", serif',
  },
};

export const getTheme = prefersDarkMode => {
  return {
    ...theme,
    palette: {
      type: prefersDarkMode ? 'dark' : 'light',
      ...theme.palette,
    }
  };
};
